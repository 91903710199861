<script lang="ts" setup>
import { useMenu } from '~/api/drupal/useMenu'
import { useAutoHide } from './useAutoHide'

const { header, transform } = useAutoHide()
const { menu } = await useMenu('meta-menu')
</script>
<template>
  <header ref="header" class="header" :style="{ ['--transform']: `${transform}px` }">
    <nav class="container">
      <Logo />
      <Navigation class="desktop-only" />
      <HeaderActions />
    </nav>
  </header>
</template>
<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  background-color: white;

  z-index: 99;

  transform: translateY(var(--transform));

  @include transitions(box-shadow);

  .is-scrolled & {
    box-shadow: var(--shadow-light-bottom);
  }

  nav {
    @include fluid(--button-action-width, 36px, 60px);

    position: relative;
    height: var(--header-bar-height);

    display: flex;
    justify-content: space-between;
    gap: 30px;
  }
}
</style>
